<template>
  <span class="spanBox" :style="customStyle">
    <slot />
  </span>
</template>
<script setup>
import { computed } from "vue";
const props = defineProps({
  sNumber: String
})
const customStyle = computed(() => {
  return ({ '--span-number': `'${props.sNumber}'` })
})
</script>
<style scoped>
.spanBox {
  font-size: 24px;
  font-weight: 300;
  line-height: 38px;
  color: #65718A;
}

.spanBox::before {
  content: var(--span-number);
  display: inline-block;
  background: #2A6AE1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: #fff;
  text-align: center;
  margin-right: 12px;
}
</style>