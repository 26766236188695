<template>
    <div class="logo">
        <img width="36" height="36" src="/images/LOGO.png" alt="图片" srcset="">
        <div class="text">
            <span class="cn">及时雨智慧平台</span>
            <span class="en">
                <span>j</span>
                <span>i</span>
                <span>s</span>
                <span>h</span>
                <span>i</span>
                <span>y</span>
                <span>u</span>
            </span>
        </div>
    </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.logo {
    color: var(--global-text-color);
    display: flex;
    align-items: center;
}

.logo img {
}

.logo .text {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
}

.logo .text .cn {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    letter-spacing: 0em;
    margin-bottom: 5px;
}

.logo .text .en {
    font-size: 8px;
    text-transform: uppercase;
    letter-spacing: 2.2em;
}

.logo .text .en>span:nth-child(even) {
    color: #4FE2ED;
}
</style>