<template>
    <canvas class="q" ref="q"></canvas>
</template>
<script setup>
import { ref, onMounted } from "vue";
const q = ref()
const props = defineProps({
    eleSize: Array,
    startPoint: Array,
    endPoint: Array,
    control1: Array,
    control2: Array,
})
/**
 * 
 * @param {dom} element canvas元素
 * @param {Array} eleSize [width, height] canvas元素的大小
 * @param {Array} startPoint [x, y] 第1个圆球开始的坐标点
 * @param {Array} endPoint [x, y] 第2个圆球结束的坐标点
 * @param {Array} control1 [x, y] 赛贝尔曲线第1个控制点的坐标位置
 * @param {Array} control2 [x, y] 赛贝尔曲线第2个控制点的坐标位置
 */
function drawSBE(
    element,
    eleSize,
    startPoint,
    endPoint,
    control1,
    control2
) {
    const [width, height] = eleSize;
    element.width = width;
    element.height = height;
    const ctx = element.getContext("2d");
    // Quadratic Bézier curve
    ctx.beginPath();
    ctx.lineWidth = 2;
    const [sX, sY] = startPoint;
    const [eX, eY] = endPoint;
    const [c1X, c1Y] = control1;
    const [c2X, c2Y] = control2;
    ctx.moveTo(sX, sY);
    ctx.bezierCurveTo(c1X, c1Y, c2X, c2Y, eX, eY);
    ctx.strokeStyle = "#2A6AE1";
    ctx.stroke();

    // Start and end points
    ctx.fillStyle = "#2A6AE1";
    ctx.beginPath();
    ctx.arc(sX, sY, 13.5, 0, 2 * Math.PI); // Start point
    ctx.arc(eX, eY, 13.5, 0, 2 * Math.PI); // End point
    ctx.fill();
}
onMounted(() => {
    const {
        eleSize,
        startPoint,
        endPoint,
        control1,
        control2
    } = props
    drawSBE(q.value,
        eleSize,
        startPoint,
        endPoint,
        control1,
        control2
    );
})
</script>
<style scoped>
.q {
    position: absolute;
    z-index: 1;
}
</style>