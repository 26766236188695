<template>
    <div class="head common-layout">
        <div class="content">
            <Logo />
            <div class="head__info">
                <div :class="activeStyle('/')" @click="$router.replace('/')">维保助手</div>
                <div :class="activeStyle('/about')" @click="$router.replace('/about')">关于我们</div>
                <div class="login">
                    <a href="https://www.jsysafe.com/jsy/wb/#/login?redirect=/dashboard">登 录</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import Logo from './Logo.vue';
import { computed, onMounted } from "vue";
import { useRouter, useRoute } from 'vue-router'
const route = useRoute()
const activeStyle = (path) => {
    return route.path === path ? 'active_style' : ''
}
</script>
<style scoped>
.head {
    background: var(--global-color-primary);
    height: 64px;
}
.head .content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.head__info {
    display: flex;
    align-items: center;
}

.head__info .login {
    padding: 0 20px;
    padding-right: 0;
}

.head__info .login a:hover {
    background-color: #145ADE;
    cursor: pointer;
}

.head__info .login a {
    box-sizing: initial;
    display: inline-block;
    padding: 5px 20px;
    text-align: justify;
    border-radius: 4px;
    border: 1px solid #fff;
    color: var(--global-text-color);
    text-decoration: none;
}

.head__info>div {
    color: var(--global-text-color);
    padding: 20px;
    position: relative;
}

.head__info>div:not(.login):hover {
    background-color: #145ADE;
    cursor: pointer;
}

.head__info>.active_style::before {
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    background: #4FE2ED;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 20px;
}

/* .head__info>div:not(.login):hover::before {
    position: absolute;
    content: '';
    height: 3px;
    background: #4FE2ED;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
} */
</style>