<template>
    <div style="position: relative;">
        <div id="gao" class="gao_map relative" />
    </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
const Map = window.AMap
const map = ref() // 地图实例
const infoWindow = ref() // 信息窗体;
const createMap = (id) => {
    if (!id) {
        throw new Event('请传入正确的id')
    }
    return (type, params) => {
        /*
         * @params {position} [116.397428, 39.90923],//中心点坐标
         * */
        const {
            position,
            animateEnable,
            zoom,
            mapStyle
        } = params
        switch (type) {
            case 'gao':
                return new Map.Map(id, {
                    center: position,
                    zoom,
                    animateEnable,
                    mapStyle
                })
        }
    }
}
const createCircle = (map) => {
    const circle = new AMap.Circle({
        center: [108.250327, 22.8667],
        radius: 70, //半径
        strokeOpacity: 1,
        strokeWeight: 1,
        strokeOpacity: 1,
        fillOpacity: 1,
        borderWeight: 3,
        strokeColor: "#2A6AE1",
        strokeStyle: 'solid',
        fillColor: '#d3e1f3',
        zIndex: 50,
    })
    map.add(circle)
}
const createInfoWindow = () => {
    //构建信息窗体中显示的内容
    var info = [];
    info.push("<p>我在<strong>和德科创中心</strong>附近</p>");
    infoWindow.value = new AMap.InfoWindow({
        content: info.join(""),  //使用默认信息窗体框样式，显示信息内容
        offset: [0, -30]
    });
}
const addMarker = (map) => {
    var marker = new AMap.Marker({
        map: map,
        position: [108.250327, 22.8665]
    });
    infoWindow.value.open(map, marker.getPosition());
    //鼠标点击marker弹出自定义的信息窗体
    marker.on('click', function () {
        infoWindow.value.open(map, marker.getPosition());
    });
}
/**
 * @description 地图配置
 * @params {  }
 * @return void
 */
function initMap() {
    return new Promise((resolve) => {
        map.value = createMap('gao')('gao', {
            position: [108.250327, 22.867334],
            zoom: 15.8,
            animateEnable: false,
            mapStyle: 'amap://styles/bf896a1d81b4c4f4b3166c4ec9e4fb05'
        })
        resolve({})
    })
}
onMounted(() => {
    initMap().then(() => {
        map.value.clearMap();
        createInfoWindow();
        addMarker(map.value);
        createCircle(map.value);
    })
})
</script>
<style scoped>
.gao_map {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 24px;
}
</style>