<template>
    <canvas class="ri" ref="ri"></canvas>
</template>
<script setup>
import { onMounted, ref } from "vue";
const ri = ref('')
function drawJt(element, width = 15, height = 40) {
    const ele = element
    const ctx = ele.getContext("2d");
    // 设置宽高
    ele.width = width
    ele.height = height
    // 线
    ctx.beginPath();
    ctx.moveTo(parseInt(width / 3), 0);
    ctx.lineTo(parseInt(width / 3), height);
    // 箭头
    ctx.lineTo(width, parseInt(height / 2));
    ctx.strokeStyle = '#2A6AE1'
    ctx.stroke();
    ctx.closePath();
}
onMounted(() => {
    const element = ri.value
    drawJt(element)
});
</script>
<style scoped></style>