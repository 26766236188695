import { createRouter, createWebHashHistory } from "vue-router";
import Layout from "@/components/Layout/index.vue";
import Home from "@/pages/Home.vue";
import About from "@/pages/About.vue";
import Test from "@/pages/Test.vue";

const routes = [
    {
        path: '/',
        component: Layout,
        children: [    
            {
                path: '',
                component: Home
            },
            {
                path: 'about',
                component: About
            },
            {
                path: 'test',
                component: Test
            },
        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router